import { PropsWithChildren } from "react";
import { Box } from "@chakra-ui/react";
import { useTableScrollStore } from "src/components/table-scrollable/store/table-scroll-store";

export const TableRightShadow = ({ children }: PropsWithChildren) => {
    const { hasScrollEndShadow } = useTableScrollStore();

    return (
        <Box
            position="relative"
            sx={{
                " ::after": {
                    content: '""',
                    position: "absolute",
                    display: "block",
                    top: 0,
                    right: 0,
                    bottom: "-1px",
                    width: 2,
                    zIndex: "docked",
                    transition: "box-shadow .3s",
                    boxShadow: hasScrollEndShadow ? "inset -14px 1px 8px -8px white" : "inset 0 0 0 0 white",
                },
            }}
        >
            {children}
        </Box>
    );
};

import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Table, Tbody, Tr, Th, Td, Box } from "@chakra-ui/react";
// eslint-disable-next-line no-restricted-imports
import { SystemStyleObject } from "@chakra-ui/system";
import { OnChangeFn, Table as ReactTable, TableOptions } from "@tanstack/react-table";
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { TableHead } from "src/components/table-scrollable/components/table-head";
import { CLASS_TABLE_CONTEXT_MENU_BUTTON } from "./components/constants";
import { TableContainerScrollObserve as TableContainerScrollObserve } from "./components/table-container-scroll-observe";
import { TableRightShadow } from "./components/table-right-shadow";

interface ITableScrollable<T extends object> {
    data: T[];
    //https://github.com/TanStack/table/issues/4382#issuecomment-1571563559
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: ColumnDef<T, any>[];
    paginationRender?: (table: ReactTable<T>) => JSX.Element;
    manualPagination?: boolean;
    manualSorting?: boolean;
    pagination?: PaginationState;
    onPaginationChange?: OnChangeFn<PaginationState>;
    rowCount?: number;
    sorting?: SortingState;
    onSortingChange?: OnChangeFn<SortingState>;
    rowStyle?: SystemStyleObject;
}

export const TableScrollable = <T extends object>({
    columns,
    data,
    pagination,
    onPaginationChange,
    paginationRender,
    manualPagination,
    manualSorting,
    rowCount,
    sorting,
    onSortingChange,
    rowStyle,
}: ITableScrollable<T>) => {
    let stateOptions = {};
    stateOptions = pagination ? { pagination } : {};
    stateOptions = sorting ? { ...stateOptions, sorting } : stateOptions;

    const tableOptions: TableOptions<T> = {
        data,
        columns,
        state: stateOptions,
        //https://tanstack.com/table/v8/docs/api/features/sorting#enablesortingremoval
        enableSortingRemoval: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        manualPagination,
        manualSorting,
        rowCount,
    };

    if (onPaginationChange) {
        tableOptions.onPaginationChange = onPaginationChange;
    }

    if (onSortingChange) {
        tableOptions.onSortingChange = onSortingChange;
    }

    const table = useReactTable(tableOptions);

    return (
        <Box>
            <TableRightShadow>
                <TableContainerScrollObserve overflowY={["hidden", "hidden", "unset"]} overflowX={["auto", "auto", "unset"]}>
                    <Table>
                        <TableHead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => {
                                        const isCustomTh = header.column.columnDef.meta?.customTh;
                                        const content = header.isPlaceholder ? null : (
                                            <React.Fragment key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </React.Fragment>
                                        );

                                        return isCustomTh ? (
                                            content
                                        ) : (
                                            <Th key={header.id} height={0} colSpan={header.colSpan} border="none" padding={0}>
                                                {content}
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </TableHead>
                        <Tbody>
                            {table.getRowModel().rows.map(row => {
                                return (
                                    <Tr
                                        key={row.id}
                                        sx={
                                            rowStyle ?? {
                                                "&:hover td, &:hover ": {
                                                    backgroundColor: "gray.50",
                                                },
                                                [`&:hover .${CLASS_TABLE_CONTEXT_MENU_BUTTON}`]: {
                                                    visibility: "visible",
                                                    marginLeft: 0,
                                                },
                                            }
                                        }
                                        borderColor="gray.100"
                                        borderBottomWidth="1px"
                                    >
                                        {row.getVisibleCells().map(cell => {
                                            const isCustomTd = cell.column.columnDef.meta?.customTd;
                                            const content = (
                                                <React.Fragment key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </React.Fragment>
                                            );

                                            return isCustomTd ? (
                                                content
                                            ) : (
                                                <Td padding={0} key={cell.id} minW={4} borderWidth={0}>
                                                    {content}
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainerScrollObserve>
            </TableRightShadow>
            {paginationRender ? paginationRender(table) : null}
        </Box>
    );
};

import { Box, Flex, Skeleton, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Span } from "src/components/base";
import { Button } from "src/components/base/button";
import { WfSelect } from "src/components/base/wf-select";
import { IconCaret } from "src/components/icons";
import { TablePaginationNumbers } from "src/components/table-scrollable/components/table-pagination-numbers";
import { scrollToTheTop } from "src/utils/dom-util";

interface ITablePaginationProps {
    pageSizeOptions: number[];
    pageSize: number;
    itemsCount: number;
    onPageSizeChange: (index: number) => void;
    currentPageIndex: number;
    pageIndexes: number[];
    onPageSelect: (index: number) => void;
    onPreviousClick: () => void;
    onNextClick: () => void;
    canGoPreviousPage: boolean;
    canGoNextPage: boolean;
    isLoading: boolean;
    itemsLabel: string;
}

const SCROLL_TOP = 300;
const TABLE_PAGINATION_TEXT_TEST_ID = "table-pagination-text";

export const TablePagination = ({
    pageSizeOptions,
    pageSize,
    itemsCount,
    onPageSizeChange,
    currentPageIndex,
    pageIndexes,
    onPageSelect,
    onPreviousClick,
    onNextClick,
    canGoPreviousPage,
    canGoNextPage,
    isLoading,
    itemsLabel,
}: ITablePaginationProps) => {
    const { t } = useTranslation("table-scrollable");
    const isMobile = useBreakpointValue([true, false]);

    const pageStartingItemNumber = (currentPageIndex + 1) * pageSize - pageSize + 1;
    const pageLastItemNumber = (currentPageIndex + 1) * pageSize;
    const isLastPage = currentPageIndex === pageIndexes.at(-1);

    const smoothScrollToTop = () =>
        window.requestAnimationFrame(() => {
            scrollToTheTop({ top: isMobile ? SCROLL_TOP : 0 });
        });

    const handleScrollToTop = (pageIndex: number) => {
        const lastPageIndex = pageIndexes.at(-1);
        const isLastPageIndex = pageIndex === pageIndexes.at(-1);
        const itemsLeftOnLastPage = itemsCount - pageIndex * pageSize;

        const isGoingBackFromLastPage = lastPageIndex === currentPageIndex && lastPageIndex > pageIndex;
        const isGoingToLastPage = isLastPageIndex && pageSize > itemsLeftOnLastPage;

        if (isGoingBackFromLastPage || isGoingToLastPage) {
            smoothScrollToTop();
        }
    };

    const handlePageSelect = (index: number) => {
        onPageSelect(index);
        handleScrollToTop(index);
    };

    const handleNextPage = (index: number) => {
        onNextClick();
        handleScrollToTop(index + 1);
    };

    const handlePreviousPage = (index: number) => {
        onPreviousClick();
        handleScrollToTop(index - 1);
    };

    const handlePageSizeChange = (pageSizeNumber: number) => {
        if (pageSizeNumber < pageSize) {
            smoothScrollToTop();
        }
        onPageSizeChange(pageSizeNumber);
    };

    return (
        <VStack mt={3} gap={1}>
            <Flex flexWrap={["wrap", "initial"]} alignItems="center" textAlign="center" justifyContent={["center", "space-between"]} w="100%">
                {isLoading ? (
                    <Skeleton height="24px" width="172px" borderRadius="4px" />
                ) : (
                    <Text color="gray.400" w={["100%", "auto"]} data-test-id={TABLE_PAGINATION_TEXT_TEST_ID}>
                        {t("pagination.range", {
                            0: pageStartingItemNumber,
                            1: isLastPage ? itemsCount : pageLastItemNumber,
                            2: itemsCount,
                            3: itemsLabel,
                        })}
                    </Text>
                )}
                <Box color="gray.400" w={["100%", "auto"]}>
                    {t("pagination.show")}
                    <Box color="gray.800" mr={"10px"} display="inline-block">
                        <WfSelect
                            backgroundColor="white"
                            iconSize="md"
                            variant="compact"
                            onChange={ev => handlePageSizeChange(Number(ev.target.value))}
                        >
                            {pageSizeOptions.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </WfSelect>
                    </Box>
                    {t("pagination.items-per-page", { 0: itemsLabel })}
                </Box>
            </Flex>
            <Flex justifyContent="space-between" w="100%">
                <Button
                    isDisabled={!canGoPreviousPage || isLoading}
                    px={[0, 2]}
                    onClick={() => handlePreviousPage(currentPageIndex)}
                    variant="outline"
                    size="sm"
                    aria-label={t("pagination.previous")}
                >
                    <IconCaret display={["inline", "none"]} transform="rotate(90deg)" fontSize="2xl" />
                    <Span display={["none", "inline"]}>{t("pagination.previous")}</Span>
                </Button>
                <TablePaginationNumbers
                    pageIndexes={pageIndexes}
                    currentPageIndex={currentPageIndex}
                    isLoading={isLoading}
                    isMobile={isMobile}
                    onPageSelect={handlePageSelect}
                />
                <Button
                    isDisabled={!canGoNextPage || isLoading}
                    px={[0, 2]}
                    onClick={() => handleNextPage(currentPageIndex)}
                    variant="outline"
                    size="sm"
                    aria-label={t("pagination.next")}
                >
                    <IconCaret display={["inline", "none"]} transform="rotate(270deg)" fontSize="2xl" />
                    <Span display={["none", "inline"]}>{t("pagination.next")}</Span>
                </Button>
            </Flex>
        </VStack>
    );
};

import { Box, HStack, Skeleton } from "@chakra-ui/react";
import { PaginationButton } from "src/components/table-scrollable/components/pagination-button";

const DOTS_INDICATOR = -9999;
const TABLE_PAGINATION_NUMBER_BUTTON_TEST_ID = "table-pagination-number-button";

const NumbersLoadingSkeleton = () => (
    <HStack gap={1}>
        <Skeleton width={4} height={4} borderRadius="4px" />
        <Skeleton width={4} height={4} borderRadius="4px" />
        <Skeleton width={4} height={4} borderRadius="4px" />
    </HStack>
);

interface ITablePaginationMenu {
    isLoading: boolean;
    currentPageIndex: number;
    pageIndexes: number[];
    isMobile?: boolean;
    onPageSelect: (index: number) => void;
}

export const TablePaginationNumbers = ({ pageIndexes, currentPageIndex, isLoading, isMobile, onPageSelect }: ITablePaginationMenu) => {
    const getMappedPaginationOptions = () => {
        if (pageIndexes.length <= 4) {
            return pageIndexes;
        }

        const lastIndex = pageIndexes.length - 1;
        let indexes: number[];

        if (currentPageIndex === pageIndexes[lastIndex]) {
            indexes = isMobile ? pageIndexes.slice(-2) : pageIndexes.slice(-3);
        } else {
            indexes = isMobile ? pageIndexes.slice(0, 2) : pageIndexes.slice(0, 3);
        }

        if (currentPageIndex > 0 && currentPageIndex < lastIndex) {
            indexes = isMobile ? [currentPageIndex] : [currentPageIndex - 1, currentPageIndex, currentPageIndex + 1];
        }

        const shouldShowLeftDots = currentPageIndex > (isMobile ? 1 : 2);
        const shouldShowRightDots = currentPageIndex < pageIndexes.length - (isMobile ? 2 : 3);
        const shouldIncludeFirstItem = currentPageIndex > (isMobile ? 0 : 1);
        const shouldIncludeLastItem = currentPageIndex < (isMobile ? lastIndex : pageIndexes.length - 2);

        if (shouldShowRightDots) {
            indexes.push(DOTS_INDICATOR);
        }

        if (shouldShowLeftDots) {
            indexes = [DOTS_INDICATOR, ...indexes];
        }

        if (shouldIncludeFirstItem) {
            indexes = [pageIndexes[0], ...indexes];
        }

        if (shouldIncludeLastItem) {
            indexes.push(pageIndexes[lastIndex]);
        }

        return indexes;
    };

    return (
        <HStack gap={1}>
            {isLoading ? (
                <NumbersLoadingSkeleton />
            ) : (
                getMappedPaginationOptions().map((index, i) =>
                    index === DOTS_INDICATOR ? (
                        <Box key={index + i}>...</Box>
                    ) : (
                        <PaginationButton
                            onClick={() => onPageSelect(index)}
                            key={index + i}
                            pageNumber={index + 1}
                            isActive={index === currentPageIndex}
                            p={0}
                            data-test-id={TABLE_PAGINATION_NUMBER_BUTTON_TEST_ID}
                        />
                    )
                )
            )}
        </HStack>
    );
};

import { Button, ButtonProps } from "src/components/base/button";

interface IPaginationButtonProps extends ButtonProps {
    pageNumber: number;
}

export const PaginationButton = ({ pageNumber, ...props }: IPaginationButtonProps) => {
    return (
        <Button
            variant="ghost"
            size="xs"
            sx={{
                bg: "transparent",
                color: "gray.800",
                _hover: {
                    bg: "gray.100",
                    color: "gray.800",
                },
                _active: {
                    bg: "gray.800",
                    color: "white",
                },
                _disabled: {
                    bg: "transparent",
                    color: "gray.200",
                },
            }}
            aria-label={String(pageNumber)}
            {...props}
        >
            {pageNumber}
        </Button>
    );
};

import { PropsWithChildren } from "react";
// eslint-disable-next-line no-restricted-imports
import { Thead } from "@chakra-ui/react";
import { useNavHeight } from "src/components/layout/hooks/use-nav-height";

// additional white background closes the gap of 8px of white spaces that is from header shadow
export const TableHead = ({ children, ...boxProps }: PropsWithChildren) => {
    const { NAV_HEIGHT_VARIATION } = useNavHeight();
    return (
        <Thead
            {...boxProps}
            backgroundColor="white"
            sx={{
                "::before": {
                    content: '""',
                    position: "absolute",
                    display: "block",
                    top: -1,
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    height: 1,
                    zIndex: "docked",
                    backgroundColor: "white",
                },
            }}
            position="sticky"
            top={[0, 0, NAV_HEIGHT_VARIATION.at(-1)!]}
            zIndex={["initial", "initial", "docked"]}
        >
            {children}
        </Thead>
    );
};

import { proxy, useSnapshot } from "valtio";
import { CONTAINER_WIDTH_ON_SCROLL } from "src/components/table-scrollable/components/constants";

export const tableScrollStore = proxy({
    // The shadow that appears after the trader cell when the user has scrolled when the table has overflow content.
    hasTraderCellShadow: false,

    // The shadow effect on the right side of the table when the user has scrolled.
    hasScrollEndShadow: false,

    shouldSnapFirstColumn: false,

    firstColumnWidth: CONTAINER_WIDTH_ON_SCROLL,

    setTraderCellShadow(hasShadow: boolean) {
        tableScrollStore.hasTraderCellShadow = hasShadow;
    },
    setScrollEndShadow(hasShadow: boolean) {
        tableScrollStore.hasScrollEndShadow = hasShadow;
    },

    setShouldSnapFirstColumn(enable: boolean) {
        tableScrollStore.shouldSnapFirstColumn = enable;
    },

    setFirstColumnWidth(width: number) {
        tableScrollStore.firstColumnWidth = width;
    },
});

export function useTableScrollStore() {
    return useSnapshot(tableScrollStore);
}

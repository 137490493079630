import { PropsWithChildren } from "react";
import { Box, useToken } from "@chakra-ui/react";
import { useTableScrollStore } from "src/components/table-scrollable/store/table-scroll-store";

export const TableBorderShadowShell = ({ children, ...boxProps }: PropsWithChildren) => {
    const [gray100] = useToken("colors", ["gray.100"]);
    const { hasTraderCellShadow } = useTableScrollStore();

    return (
        <Box
            height="100%"
            {...boxProps}
            sx={{
                " ::after": {
                    content: '""',
                    position: "absolute",
                    display: "block",
                    top: 0,
                    right: 0,
                    bottom: "-1px",
                    pointerEvents: "none",
                    width: hasTraderCellShadow ? 2 : 0,
                    transition: "box-shadow .3s",
                    transform: "translate(100%)",
                    boxShadow: hasTraderCellShadow ? `inset 10px 0 8px -8px ${gray100}` : `inset 0 0 0 0 ${gray100}`,
                },
            }}
        >
            {children}
        </Box>
    );
};

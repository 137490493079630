import { PropsWithChildren } from "react";
import { AnimatePresence } from "framer-motion";
import { MotionBox } from "src/components/base";
import { TABLE_FIRST_COLUMN_WIDTH } from "src/components/table-scrollable/components/constants";
import { useTableScrollStore } from "src/components/table-scrollable/store/table-scroll-store";

export const TableStickyCellAnimateContainer = ({ children }: PropsWithChildren) => {
    const { shouldSnapFirstColumn, firstColumnWidth } = useTableScrollStore();
    const width = shouldSnapFirstColumn ? `${firstColumnWidth}px` : undefined;

    return (
        <AnimatePresence>
            <MotionBox
                position="relative"
                transition={{ bounce: 0 }}
                animate={{ width }}
                display="flex"
                width={TABLE_FIRST_COLUMN_WIDTH}
                flexFlow={["row-reverse", "row-reverse", "row"]}
                height="100%"
                borderRightWidth={["2px", "2px", 0]}
                justifyContent={["space-between"]}
                alignItems="center"
                py={[1, 1, 2]}
                paddingRight={[1, 1, 2]}
                paddingLeft={[0, 0, 2]}
                borderColor="gray.100"
            >
                {children}
            </MotionBox>
        </AnimatePresence>
    );
};

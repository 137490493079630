import { PropsWithChildren } from "react";
// eslint-disable-next-line no-restricted-imports
import { BoxProps, Flex, FlexProps } from "@chakra-ui/react";
import { Header } from "@tanstack/react-table";
import { TableSortingIcon } from "src/components/table-scrollable/components/table-sorting-icon";
import { TABLE_HEADER_ELEMENT_HEIGHT } from "./constants";

interface ITableRowCell<T> extends BoxProps {
    header: Header<T, unknown>;
    alignCenter?: boolean;
}

export const TableRowCell = <T,>({ children, header, alignCenter, ...boxProps }: PropsWithChildren<ITableRowCell<T>>) => {
    const buttonProps: FlexProps =
        header.column.columnDef.enableSorting === false
            ? {}
            : {
                  _hover: { color: "gray.600" },
                  onClick: header.column.getToggleSortingHandler(),
                  fontWeight: "bold",
                  as: "button",
                  width: "100%",
                  textAlign: "right",
              };

    return (
        <Flex
            height={TABLE_HEADER_ELEMENT_HEIGHT}
            alignItems="flex-start"
            borderColor="gray.100"
            borderBottomWidth="2px"
            py={2}
            px={1}
            justifyContent={alignCenter ? "center" : "flex-end"}
            {...boxProps}
            {...buttonProps}
        >
            {children}
            <TableSortingIcon header={header} />
        </Flex>
    );
};

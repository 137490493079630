import { UIEvent, PropsWithChildren, useEffect, useRef, useCallback, MutableRefObject } from "react";
import { BoxProps, TableContainer, useBreakpointValue } from "@chakra-ui/react";
import { useTableScrollStore } from "src/components/table-scrollable/store/table-scroll-store";
import { calculatePercentage } from "src/utils/numbers/calculate-percentage";
import { isHorizontalOverflown } from "../utils";
import { CONTAINER_WIDTH_ON_SCROLL, SCROLL_PERCENTAGES_TO_SNAP, TABLE_FIRST_COLUMN_WIDTH } from "./constants";

/**
 * Observes and manages scroll events for a table container.
 */
const OFFSET = 1;
export const TableContainerScrollObserve = ({ children, ...boxProps }: PropsWithChildren<BoxProps>) => {
    const { setTraderCellShadow, setScrollEndShadow, setShouldSnapFirstColumn, setFirstColumnWidth } = useTableScrollStore();
    const ref = useRef<HTMLDivElement | null>(null);
    const firstColumnWidth = useBreakpointValue(TABLE_FIRST_COLUMN_WIDTH) ?? "";

    const getOverflowWidth = useCallback((element: HTMLElement) => {
        return element.scrollWidth - element.clientWidth;
    }, []);

    const calculateFirstColumnShrunkWidth = useCallback(
        (ref: MutableRefObject<HTMLDivElement | null>) => {
            if (ref.current && isHorizontalOverflown(ref.current)) {
                const overflowWidth = getOverflowWidth(ref.current);

                // we add slight offset so scrollbar would still be visible when column is shrunk
                const adjustedColumnWidth = Number(firstColumnWidth.replace("px", "")) + OFFSET;

                setFirstColumnWidth(
                    overflowWidth > adjustedColumnWidth - CONTAINER_WIDTH_ON_SCROLL ? CONTAINER_WIDTH_ON_SCROLL : adjustedColumnWidth - overflowWidth
                );

                // reset horizontal scroll
                ref.current.scrollLeft = 0;
            }
        },
        [firstColumnWidth, getOverflowWidth, setFirstColumnWidth]
    );

    useEffect(() => {
        if (ref.current) {
            setScrollEndShadow(isHorizontalOverflown(ref.current));
        }

        const onResize = () => {
            calculateFirstColumnShrunkWidth(ref);
        };
        window.addEventListener("resize", onResize);

        return () => window.removeEventListener("resize", onResize);
    }, [calculateFirstColumnShrunkWidth, setScrollEndShadow]);

    useEffect(() => {
        calculateFirstColumnShrunkWidth(ref);
    }, [calculateFirstColumnShrunkWidth, children]);

    const handleOnScroll = (event: UIEvent<HTMLElement>) => {
        const element = event.target as HTMLDivElement;
        const scrollPercentage = calculatePercentage(element.scrollLeft, getOverflowWidth(element), true);
        const scrollEndShadow = element.clientWidth + element.scrollLeft !== element.scrollWidth;

        setTraderCellShadow(element.scrollLeft > 0);
        setScrollEndShadow(scrollEndShadow);
        setShouldSnapFirstColumn(scrollPercentage > SCROLL_PERCENTAGES_TO_SNAP);
    };

    return (
        <TableContainer ref={ref} {...boxProps} position="relative" onScroll={handleOnScroll}>
            {children}
        </TableContainer>
    );
};

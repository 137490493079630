import { Header } from "@tanstack/react-table";
import { IconCaret } from "src/components/icons";

interface ITableSortingIcon<T> {
    header: Header<T, unknown>;
}

export const TableSortingIcon = <T,>({ header }: ITableSortingIcon<T>) => {
    if (header.column.columnDef.enableSorting === false) {
        return null;
    }

    const rotationAngle = header.column.getIsSorted() === "desc" ? 0 : 180;

    return (
        <IconCaret
            ml={0.5}
            boxSize={2}
            color={header.column.getIsSorted() ? "gray.800" : "gray.300"}
            transition="transform 0.2s"
            transform={`rotate(${rotationAngle}deg)`}
        />
    );
};

/**
 * Checks if an HTML element is horizontally overflown.
 *
 * @param element - The HTML element to check for horizontal overflow.
 *
 * @returns `true` if the element is horizontally overflown; `false` otherwise.
 */
export function isHorizontalOverflown(element: HTMLDivElement): boolean {
    return element.scrollWidth > element.clientWidth;
}

export const getTableDataForLoading = (pageSize: number, pageIndex: number, totalItems: number) => {
    const defaultLoadingData = Array(pageSize).fill({});
    if (totalItems === 0) {
        return defaultLoadingData;
    }

    const pageItems = (pageIndex + 1) * pageSize;
    if (pageItems > totalItems) {
        return new Array(pageSize - (pageItems - totalItems)).fill({});
    }

    return defaultLoadingData;
};
